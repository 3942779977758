<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1770_1016" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1770_1016)">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.7445 12.7983L11.6882 17.7844C10.8078 18.6622 9.48691 17.3455 10.3674 16.4675L13.3113 13.5322C13.5573 13.2871 13.4761 13.0908 13.1335 13.0908H2.93391C2.418 13.0908 2 12.6741 2 12.1597C2 11.6453 2.41798 11.2284 2.93391 11.2284H13.1335C13.4757 11.2284 13.5558 11.0308 13.3113 10.787L10.3674 7.85149C9.48691 6.97383 10.8078 5.65671 11.6882 6.53459L16.7416 11.4885C17.1097 11.8492 17.1116 12.4361 16.7444 12.7983L16.7445 12.7983ZM20.1324 5.93391C20.1324 5.41822 20.5504 5 21.0663 5C21.582 5 22 5.4182 22 5.93391V18.3853C22 18.901 21.582 19.3192 21.0663 19.3192C20.5504 19.3192 20.1324 18.901 20.1324 18.3853V5.93391Z"
            fill="#1E70BB"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconLogout"
}
</script>

<style scoped>

</style>